// import { PureCounter } from '@srexi/purecounterjs';
import NavBar from '../components/Navbar';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import StatCounter from '../components/StatCounter';



function Home() {

    const stats = [
        {name: 'Particuliers', value: 3978, duration: 3},
        {name: 'Entreprises', value: 735, duration: 1.5},
        {name: 'Utilisateurs', value: 4713, duration: 3}
    ]

    return (
        <>
            {/* Navigation */}
            <NavBar/>
            {/* End of navigation */}

            {/* Header */}
            <header id="header"  className="header">
                <img  className="decoration-star" src="images/decoration-star.svg" alt="alternative"/>
                <img  className="decoration-star-2" src="images/decoration-star.svg" alt="alternative"/>
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-7 col-xl-5">
                            <div  className="text-container">
                                <h1  className="h1">Libérez votre créativité à l'ère du digital...</h1>
                                <p  className="p-large">Plus de 30 modules numériques klapeers, sécurirsés et certifiés ISO 27001, DSS, PCI</p>
                                <a  className="btn-solid-lg" href="#introduction">En savoir plus</a>
                                <a  className="btn-outline-lg" href="#contact">Contactez-nous</a>
                            </div>
                        </div>
                        <div  className="col-lg-5 col-xl-7">
                            <div  className="image-container">
                                <img  className="img-fluid" src="images/header.png" alt="alternative"/>
                            </div>
                        </div> 
                    </div> 
                </div>
            </header>
            {/* End of header */}

            {/* Statistics */}
            <div  className="counter text-center">
                <div  className="container">
                    <div  className="row justify-content-center">
                        <div  className="col-lg-12 d-flex justify-content-around flex-wrap align-items-center">
                            {stats.map((props) => <StatCounter key={props.name} {...props} />)}
                        </div>
                    </div>
                </div>
            </div>

            {/* Introduction */}
            <div id="introduction"  className="basic-1 bg-gray">
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-6 col-xl-7">
                            <h2>Une proposition de valeur unique à 360° dans un écosystème à très forte valeur ajoutée</h2>
                            <p>une expérience digitale panafricaine innovante tout-en-un.</p>
                            <p>Prix du meilleur projet technologique 2021 de l'Association professionnelle des établissements de crédit du Cameroun : APECCAM</p>
                        </div> 
                        <div  className="col-lg-6 col-xl-5">
                            <img  className="img-fluid" src="images/prix_apeccam_awards_2021.png" alt="alternative"/>
                        </div> 
                        
                    </div> 
                </div> 
            </div> 
            
            {/* Details 1 */}
            <div id="details"  className="basic-2">
                <img  className="decoration-star" src="images/decoration-star.svg" alt="alternative"/>
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-6 col-xl-5">
                            <div  className="image-container">
                                <img  className="img-fluid" src="images/details-1.png" alt="alternative"/>
                            </div> 
                        </div> 
                        <div  className="col-lg-6 col-xl-7">
                            <div  className="text-container">
                                <h2>L'univers klapeers disponible 24h/7 en toute mobilité</h2>
                                <ul  className="list-unstyled li-space-lg">
                                    <li  className="d-flex">
                                        <i  className="fas fa-square"></i>
                                        <div  className="flex-grow-1"><strong>La sécurité</strong><br />Nous assurons les mesures de sécurité rigoureuses des banques au sein de notre écosystème.</div>
                                    </li>
                                    <li  className="d-flex">
                                        <i  className="fas fa-square"></i>
                                        <div  className="flex-grow-1"><strong>La confidentialité</strong><br />Vos données sont en sécurité, nous mettons en permanace à jour notre conformité au RGPD</div>
                                    </li>
                                    <li  className="d-flex">
                                        <i  className="fas fa-square"></i>
                                        <div  className="flex-grow-1"><strong>Le paiement dématérialisé</strong><br />Rapides, sécurisés, fiables, nos processus de paiement, collecte et de transfert de fond sont adaptables à vos besoins</div>
                                    </li>
                                    <li  className="d-flex">
                                        <i  className="fas fa-square"></i>
                                        <div  className="flex-grow-1"><strong>Des solutions sur mesure</strong><br />Une palette de plus de 30 modules pour vous accompagner dans une expérience digitale positive et unique</div>
                                    </li>
                                </ul>
                            </div> 
                        </div> 
                    </div>  
                </div>  
            </div> 
            
            {/* Invitations */}
            <div  className="basic-4 bg-gray">
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-12">
                            <h4>Service de très haute qualité même pendant les pics de trafic</h4>
                            <div  className="container">
                                <img src="./images/pg.png"  className="img-fluid" alt="paiement gateway"/>
                            </div>
                        </div> 
                    </div>  
                </div>  
            </div> 
       
            {/* Details2 */}
            <div  className="basic-3">
                <img  className="decoration-star" src="images/decoration-star.svg" alt="alternative"/>
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-6 col-xl-7">
                            <div  className="text-container">
                                <h2>Une gamme complète d'équipement numérique adapté à votre secteur d'activité</h2>
                                <p>Des EFTPOS, en passant par des POS, des Kiosques, des caisses enregistreuses, des dispositifs biométriques, des validateurs de billets, des robots avec intelligence artificielle et des équipements VIOT, nous assurons une sélection minutieuse auprès de nos partenaires stratégiques afin de vous offrir des outils adaptés au besoin de vos projets.</p>
                                <Link  className="btn-solid-reg" to="/gamme">En savoir plus</Link>
                            </div> 
                        </div> 
                        <div  className="col-lg-6 col-xl-5">
                            <div  className="image-container">
                                <img  className="img-fluid" src="images/details-2.png" alt="alternative"/>
                            </div> 
                        </div> 
                    </div>  
                </div>  
            </div> 
            
            {/* Services */}
            <div id="services"  className="cards-1 bg-gray">
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-5">
                            <div  className="text-container">
                                <h2>Proposez ces types de paiement à vos clients</h2>
                                <p>Nous avons simplifié le processus de paiement</p>
                            
                                <ul  className="list-unstyled li-space-lg">
                                    <li  className="d-flex">
                                        <i  className="fas fa-square"></i>
                                        <div  className="flex-grow-1">Acceptez les cartes de débits/crédit, les paiements sans contact et les chèques électroniques en personne et en déplacement.</div>
                                    </li>
                                    <li  className="d-flex">
                                        <i  className="fas fa-square"></i>
                                        <div  className="flex-grow-1">Acceptez les paiements par QR Code</div>
                                    </li>
                                    <li  className="d-flex">
                                        <i  className="fas fa-square"></i>
                                        <div  className="flex-grow-1">Acceptez tous les types de paiement mobile money</div>
                                    </li>
                                </ul>
                            </div> 
                        </div> 
                        <div  className="col-lg-7">
                            <div  className="card-container">
                                <div  className="card">
                                    <img src="images/commerce_electronique.png" alt="DHL"/>
                                    <p><strong>Commerce électronique</strong></p>
                                </div>
                                <div  className="card">
                                    <img src="images/point_de_vente.png" alt="DHL"/>
                                    <p><strong>Point de vente</strong></p>
                                </div>                           
                                <div  className="card">
                                    <img src="images/paiement_mobiles.png" alt="DHL"/>
                                    <p><strong>Paiements mobiles</strong></p>
                                </div> 
                                <div  className="card">
                                    <img src="images/paiements_par_telephone.png" alt="DHL"/>
                                        <p><strong>Paiements par téléphone</strong></p>
                                </div>
                                 
                                <div  className="card">
                                    <img src="images/echeck.png" alt="DHL"/>
                                    <p><strong>eCheck</strong></p>
                                </div>
                                 
                                <div  className="card">
                                    <img src="images/lecteur_de_cartes_mobile.png" alt="DHL"/>
                                    <p><strong>Lecteur de cartes mobiles</strong></p>
                                </div>
                                 
                                <div  className="card">
                                    <img src="images/facturation.png" alt="DHL"/>
                                    <p><strong>Facturation</strong></p>
                                </div>
                                <div  className="card">
                                    <img src="images/visa.png" alt="DHL"/>
                                    <img src="images/mastercard.png" alt="DHL"/>
                                </div>
                                 
                            </div>  
                        </div> 
                    </div>  
                </div>  
            </div>
        
            {/* Contact */}
            <div id="contact"  className="form-1 container">
                <img  className="decoration-star" src="images/decoration-star.svg" alt="alternative"/>
                <img  className="decoration-star-2" src="images/decoration-star.svg" alt="alternative"/>
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-6">
                            <div  className="image-container">
                                <img  className="img-fluid" src="images/contact.png" alt="alternative"/>
                            </div> 
                        </div> 
                        <div  className="col-lg-6">
                            <div  className="text-container">
                                <h2>Contactez notre équipe d'experts et discutons ensemble de votre projet</h2>

                                <form>
                                    <div  className="form-group">
                                        <input type="text"  className="form-control-input" placeholder="Nom" required/>
                                    </div>
                                    <div  className="form-group">
                                        <input type="email"  className="form-control-input" placeholder="Email" required/>
                                    </div>
                                    <div  className="form-group">
                                        <input type="numbers"  className="form-control-input" placeholder="Telephone" required/>
                                    </div>
                                    <div  className="form-group">
                                        <input type="text"  className="form-control-input" placeholder="Objet" required/>
                                    </div>
                                    <div  className="form-group">
                                        <textarea  className="form-control-textarea" placeholder="Message" required></textarea>
                                    </div>
                                    <div  className="form-group">
                                        <button type="submit"  className="form-control-submit-button">Envoyer</button>
                                    </div>
                                </form>
                                
                            </div> 
                        </div> 
                    </div>  
                </div>  
            </div>

            {/* Footer */}
            <div  className="footer bg-gray">
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-12">
                            <div  className="footer-col first">
                                <h6>A propos de Klapeers</h6>
                                <p  className="p-small">Depuis 2022, Klapeers est le partenaire technique des villes africaines et développe le concept de Smart City en afrique subsaharienne.</p>
                            </div>  
                            <div  className="footer-col second">
                                <h6>Accès rapide</h6>
                                <ul  className="list-unstyled li-space-lg p-small">
                                    <li><a href="#header">Accueil</a>, <a href="#details">A propos de nous</a>, <a href="#services">Produits</a>, <a href="#services">Solutions</a>, <a href="#services">Klapeers Cloud</a>, <a href="#services">Développeurs</a>, <a href="#contact">Contact</a></li>
                                    <li><a href="#">Termes et conditions</a>, <a href="#">Politique de confidentialité</a></li>
                                    
                                    
                                </ul>
                            </div>  
                            <div  className="footer-col third">
                            
                                <span  className="fa-stack">
                                    <a href="https://www.klapeers.com">
                                        <img src="images/klapeers_social.png" alt="klapeers"/>
                                    </a>
                                </span>
                                <p  className="p-small">Make it Possible. <a href="mailto:contact@site.com"><strong>afrique@klapeers.com</strong></a></p>
                            </div>  
                        </div> 
                    </div>  
                </div>  
            </div>
            {/* End of footer */}

            {/* Copyright */}
            <div  className="copyright bg-gray">
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-12">
                            <p  className="p-small">© Tous droits réservés 2024, <a href="index.html">Klapeers</a></p>
                        </div> 
                    </div>
                </div>  
            </div>
            
        </>
    )
}

export default Home;