import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function FreeSaas() {
    
    return (
        <>
            <NavBar/>
            {/* Start content */}
            <div class="basic-3">
                <img class="decoration-star" src="images/decoration-star.svg" alt="alternative"/>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-xl-7">
                            <div class="text-container">
                                <h2>Klapeers Cloud Center : Un système SaaS complet</h2>
                                <p>En réponse aux cycles de développement longs, difficiles et coûteux auxquels les clients sont souvent confrontés, Klapeers a développé son propre système de gestion intégré au "Klapeers Center" basé sur plusieurs scénarios d'appareils intelligents. Permettant de faire correspondre le module externe, tel que le clavier numérique, le module de scanner d'empreintes digitales biométrique, le contrôle fiscal, etc.</p>
                                <p>Klapeers Center est une plate-forme saas de dispositifs de gestion mobile conçue pour briser les frontières régionales et fournir des services à valeur ajoutée aux clients. Il est gratuit pour tous les membres de <a href="https://www.klapeers.com" target="_blank">l'écosystème Klapeers</a> et payant pour les non membres</p>
                                <a class="btn-solid-reg" href="solutions.html#saas">En savoir plus</a>
                            </div> 
                        </div> 
                        <div class="col-lg-6 col-xl-5">
                            <div class="image-container">
                                <img class="img-fluid" src="images/center.png" alt="klapeers center"/>
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div> 
            
            <div id="introduction" class="basic-1 bg-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-xl-7">
                            <h2>Klapeers Cloud Center vous apporte la souplesse et la flexibilité dont vous avez besoin</h2>
                            <p>Déploie instantanément un système d'entreprise "Cloud + Terminal". Vous vous consacrez essentiellement à votre coeur de métier : Nos équipes s'occupent du reste.</p>
                        </div> 
                        <div class="col-lg-6 col-xl-5">
                            <img class="img-fluid" src="images/cloud_2.png" alt="alternative"/>
                        </div> 
                        
                    </div> 
                </div> 
            </div> 
            
            <div id="details" class="basic-2">
                <img class="decoration-star" src="images/decoration-star.svg" alt="alternative"/>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-xl-5">
                            <div class="image-container">
                                <img class="img-fluid" src="images/retail_banking.png" alt="alternative"/>
                            </div> 
                        </div> 
                        <div class="col-lg-6 col-xl-7">
                            <div class="text-container">
                                <h2>Nos solutions bancaires de détail aident les clients à définir comment leurs clients et les générations futures géreront leur argent à l'avenir.</h2>
                                <ul class="list-unstyled li-space-lg">
                                    <li class="d-flex">
                                        <i class="fas fa-square"></i>
                                        <div class="flex-grow-1"><strong>Ecosystème Klapeers</strong><br />Une version fiable, sécurisée et spéciale de notre écosystème pour les banques et les institutions financières. Il est livré avec des connecteurs prêts à l'emploi qui offrent une connectivité facile aux systèmes bancaires principaux, y compris les systèmes bancaires de base, le CRM, l'ERP et d'autres systèmes.</div>
                                    </li>
                                    <li class="d-flex">
                                        <i class="fas fa-square"></i>
                                        <div class="flex-grow-1"><strong>Banque à distance</strong><br />Une expérience immersive et engageante pour permettre des opérations bancaires « presque réelles ». Activer plusieurs workflows, fournir des informations sur les nouveaux services/produits ou interagir avec eux.</div>
                                    </li>
                                    <li class="d-flex">
                                        <i class="fas fa-square"></i>
                                        <div class="flex-grow-1"><strong>Services bancaires omnicanaux optimisé par Klapeers</strong><br />La solution fournit des connecteurs et des flux de travail prêts à l'emploi qui permettent un processus bancaire intelligent transparent</div>
                                    </li>
                                    <li class="d-flex">
                                        <i class="fas fa-square"></i>
                                        <div class="flex-grow-1"><strong>La banque numérique</strong><br />Une expérience immersive, sécurisée, stable, personnalisée et centrée sur l'humain pour vos clients grâce à notre ecisystème Klapeers</div>
                                    </li>
                                </ul>
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div> 
            
            <div id="introduction" class="basic-1 bg-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-xl-7">
                            <h2>Une proposition de valeur unique à 360° dans un écosystème à très forte valeur ajoutée</h2>
                            <p>une expérience digitale panafricaine innovante tout-en-un.</p>
                            <p>Prix du meilleur projet technologique 2021 de l'Association professionnelle des établissements de crédit du Cameroun : APECCAM</p>
                        </div> 
                        <div class="col-lg-6 col-xl-5">
                            <img class="img-fluid" src="images/prix_apeccam_awards_2021.png" alt="alternative"/>
                        </div> 
                        
                    </div> 
                </div>
            </div> 

            <div id="contact" class="form-1">
                <img class="decoration-star" src="images/decoration-star.svg" alt="alternative"/>
                <img class="decoration-star-2" src="images/decoration-star.svg" alt="alternative"/>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="image-container">
                                <img class="img-fluid" src="images/contact.png" alt="alternative"/>
                            </div>/
                        </div> 
                        <div class="col-lg-6">
                            <div class="text-container">
                                <h2>Contactez notre équipe d'experts et discutons ensemble de votre projet</h2>

                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control-input" placeholder="Nom" required/>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control-input" placeholder="Email" required/>
                                    </div>
                                    <div class="form-group">
                                        <input type="numbers" class="form-control-input" placeholder="Telephone" required/>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control-input" placeholder="Objet" required/>
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control-textarea" placeholder="Message" required></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="form-control-submit-button">Envoyer</button>
                                    </div>
                                </form>
                                
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div>
            {/* End Content */}
            <FooterAndCopyright/>
        </>
    )
}
