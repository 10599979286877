import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function Contact(){

    return (

        <>
            <NavBar/>
            {/* Start content */}
            <div id="contact" class="form-1">
                <img class="decoration-star" src="images/decoration-star.svg" alt="alternative"/>
                <img class="decoration-star-2" src="images/decoration-star.svg" alt="alternative"/>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="image-container">
                                <img class="img-fluid" src="images/contact.png" alt="alternative"/>
                            </div>/
                        </div> 
                        <div class="col-lg-6">
                            <div class="text-container">
                                <h2>Contactez notre équipe d'experts et discutons ensemble de votre projet</h2>

                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control-input" placeholder="Nom" required/>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control-input" placeholder="Email" required/>
                                    </div>
                                    <div class="form-group">
                                        <input type="numbers" class="form-control-input" placeholder="Telephone" required/>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control-input" placeholder="Objet" required/>
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control-textarea" placeholder="Message" required></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="form-control-submit-button">Envoyer</button>
                                    </div>
                                </form>
                                
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div>
            {/* End Content */}
            <FooterAndCopyright/>
        </>
    )
}