import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function KlapeersCard() {
    
    return (
        <>
            <NavBar/>
            {/* Start content */}

            {/* Header */}
            <header class="ex-header bg-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h1>Klapeers Card visa prepaid</h1>
                        </div> 
                    </div> 
                </div> 
            </header>

            {/* Basic */}
            <div class="ex-basic-1 pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <img class="img-fluid mt-5 mb-3" src="images/klapeers_card.jpg" alt="klapeers_card"/>
                        </div> 
                    </div> 
                </div> 
            </div>

            {/* Basic */}
            <div class="ex-basic-1 pt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <p>Alors que les consommateurs se tournent vers le numérique, ils exigent un plus grand contrôle sur leurs paiements. Klapeers Card permet aux consommateurs de contrôler à distance comment, quand et où leurs cartes numériques sont utilisées, offrant un contrôle et une visibilité améliorés sur les dépenses, renforçant la sécurité et minimisant les cas d’escroqueries financières.</p>
                            <p>La carte visa prépayée Klapeers, vous assure un confort et une sécurité d'usage dans les stations services du monde entier.</p>
                            <p>Réservez désormais vos billets et tickets de voyages en toute confiance avec les cartes visa prépayées Klapeers, elles sont dotées de la technologie 3D Secure.</p>
                            <p>Quelque soit l'endroit du globe où vous vous trouvez, la carte visa prépayées klapeers vous accompagne. Elle est multi-dévise.</p>
                            <p>Contrôlez désormais ce que vous dépensez, la carte visa prépayées klapeers est votre porte-monnaie électronique.</p>
                            <p>Pour vos services du quotidien, la carte visa prépayée klapeers est votre compagnons de tous les jours.</p>
                            <p>Achetez sur internet en toute sécurité avec les cartes visa prépayées klapeers. Elles ne sont reliées à aucun compte bancaire.</p>
                            <p>Sur internet ou en magasin, la carte visa prépayées klapeers est le mode de paiement adapté à vos besoins.</p>
                            
                            <p class="mb-5">Pour le paiement de vos frais scolaires ou toutes autres factures de biens et services, la carte visa prépayées klapeers, saura vous satisfaire.</p>
                            
                            <h2 class="mb-3">PLUS J'EN PARLE, PLUS JE GAGNE<br /> Parrainez vos proches et gagnez plus...</h2>
                            <p>Cumulez des points XP (experience point) afin de Vivre une expérience positive, unique en Afrique et dans le monde avec les cartes visa prépayées Klapeers. Rejoignez le club Klapeers.</p>
                            <p class="mb-4">Tous les membres du Club Klapeers peuvent participer au XP PROGRAM. 
                            XP PROGRAM de Klapeers, est un programme de fidélité qui récompense les utilisateurs de la carte visa prépayée klapeers en leur faisant bénéficier des privilèges au sein du réseau de partenaires klapeers afin de leurs garantir un expérience unique et positive. 
                            XP = Experience point</p>
                        </div> 
                    </div> 
                </div> 
            </div>

            {/* Basic */}
            <div class="ex-basic-1 pt-3 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h2 class="mb-4">XP PROGRAM : Comment ça marche ?</h2>
                            <img class="img-fluid mb-5" src="images/xp.jpg" alt="alternative"/>
                            <p>Tout commence par l'achat d'une carte visa prépayée klapeers et vous devenez automatiquement un membre du Club klapeers.
                            Lors de votre achat, votre carte est activée et vous pouvez la recharger instantannément.
                            Une fois votre carte activée et chargée, vous pouvez l'utiliser pour des paiements en magasin, des paiements en ligne et des retraits dans les GAB.</p>
                            <div class="text-box mb-5">
                                <h3>Pourquoi parrainer un filleul ?</h3>
                                <p>Parrainer un filleul dans le Club Klapeers, c'est tout d'abord aider un proche tout en vous permettant de financer un de vos projets ainsi que celui de votre proche. Tout le monde y gagne : C'est un développement participatif au sein d'une communauté.</p>
                            </div>
                            
                            <ul class="list-unstyled li-space-lg mb-5">
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Acceptation dans plus de 30 millions de magasins dans le monde</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Retrait d'espèce au niveau de 2 millions de DAB</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Operations sécurisées d'achant en ligne</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Pas besoin de compte bancaire</strong> </div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Gestion des transactions financières de votre carte en ligne</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Transfert d'argent de carte à cate gratuit</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Paiement des factures diverses (eau, electricité, etc...)</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Cumul de points à chaque franc dépensé</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Parrainage des proches et gain des points d'expérience</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Avantages spéciaux dans le réseau des partenaires</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>3 Modes de recharge</strong></div>
                                </li>
                                <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <div class="flex-grow-1"><strong>Le service client disponible 24h/7</strong></div>
                                </li>
                            </ul>
                            
                            <a class="btn-solid-reg mb-5" href="/contact">Contactez-nous</a>
                        </div> 
                    </div> 
                </div> 
            </div>
    
            {/* End Content */}
            <FooterAndCopyright/>
        </>
    )
}


    
    



    
    
