import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function Gamme() {
    
    return (
            <>
                <NavBar/>
                {/* Start content */}

                <header class="ex-header bg-gray">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-10 offset-xl-1">
                                <h1>Une gamme complète d'équipement smart</h1>
                            </div> 
                        </div> 
                    </div> 
                </header> 

                <div class="ex-basic-1 pt-5 pb-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <img class="img-fluid mt-5 mb-3" src="images/article-details-large.jpg" alt="alternative"/>
                            </div> 
                        </div> 
                    </div> 
                </div> 
                
                <div class="ex-basic-1 pt-4">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-10 offset-xl-1">
                                <p>Des partenariats stratégiques avec des fabriquants leaders dans leur secteur respectif nous permettent de vous offir des équipements adaptés à votre projet. Il s'agit des SmartPOS, Portable, Desktop, PIN PAD, miniPOS, mPOS, Reconnaissance faciale, Kiosques, QR Terminals, Smart ECR, DAB, STM, DCM et accessoires divers </p>
                                <p class="mb-5">Après une étude minitieuse de votre projet, nous vous conseillons l'équipement qui se connectera facilement à la solution logiciel qui répondra pleinement à vos attentes. Cela passe par un accompagnement complet. De la fourniture de l'équipement en passant par l'implémentation de la solution logiciel et le monitoring permanent de l'équipement.</p>

                                <h2 class="mb-3">SmartPOS, Portable, Desktop, PIN PAD, miniPOS, mPOS, Reconnaissance faciale, Kiosques, QR Terminals, Smart ECR, DAB, STM, DCM</h2>
                                <p>Facile à utiliser, facile à installer, facile à configurer, maintenance aisée et une sécurité optimale, sont les caractéristiques que nous privilégions lors de notre recommandation des équipements pour votre projet.</p>
                                <p class="mb-4">Après 20 ans de développement d'application métiers en mode PAAS et SAAS, Klapeers est maintenant une compagnie mondiale acteur influent de la technologie dans le continent Africain et fournisseur des terminaux de paiement et de vente.</p>
                            </div> 
                        </div> 
                    </div> 
                </div> 

                <div class="ex-cards-1 pt-3 pb-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                
                                <div class="card">
                                    <ul class="list-unstyled">
                                        <li class="d-flex">
                                            <span class="fa-stack">
                                                <span class="fas fa-circle fa-stack-2x"></span>
                                                <span class="fa-stack-1x">1</span>
                                            </span>
                                            <div class="flex-grow-1">
                                                <h5>Facile à utiliser</h5>
                                                <p>Nos équipements sont userfriendly, une adaptation intuitive vous facilite la prise en main.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div> 
                                <div class="card">
                                    <ul class="list-unstyled">
                                        <li class="d-flex">
                                            <span class="fa-stack">
                                                <span class="fas fa-circle fa-stack-2x"></span>
                                                <span class="fa-stack-1x">2</span>
                                            </span>
                                            <div class="flex-grow-1">
                                                <h5>Maintenance aisée</h5>
                                                <p>Vos équipes techniques n'auront aucun mal à rapidement comprendre le processus d'entretien.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div> 
                                <div class="card">
                                    <ul class="list-unstyled">
                                        <li class="d-flex">
                                            <span class="fa-stack">
                                                <span class="fas fa-circle fa-stack-2x"></span>
                                                <span class="fa-stack-1x">2</span>
                                            </span>
                                            <div class="flex-grow-1">
                                                <h5>Sécurité optimale</h5>
                                                <p>Des Certificats de sécurité sont régulièrement révisés afin d'éprouver suffisant les équipements avant leur mise en production.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div> 
                            </div>    
                        </div>    
                    </div>   
                </div> 
                
                <div class="ex-basic-1 pt-3 pb-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-10 offset-xl-1">
                                <img class="img-fluid mb-5" src="images/atm.jpg" alt="alternative"/>
                                <div class="text-box mb-5">
                                    <h3>Une technologie de pointe</h3>
                                    <p>Les équipements de nos partenaires son sans cesse challengés par nos équipes d'expert afin de toujours vous offrir le meilleur outil le plus adapté et le plus abouti pour votre projet.</p>
                                </div> 
                            
                                
                                <a class="btn-solid-reg mb-5" href="/contact">Contactez-nous</a>
                            </div> 
                        </div>
                    </div>
                </div>
                {/* End Content */}
                <FooterAndCopyright/>
        </>
    )
}

    