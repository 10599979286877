import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function DistributeurAutoBillet() {
    
    return (
        <>
            <NavBar/>
            {/* Start content */}
            <header class="ex-header bg-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h1>Distributeur automatique de billet (DAB)</h1>
                        </div> 
                    </div>
                </div> 
            </header> 
    
            <div class="ex-basic-1 pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <img class="img-fluid mt-5 mb-3" src="images/dab.jpg" alt="DAB"/>
                        </div>
                    </div> 
                </div> 
            </div> 
        
            <div class="ex-basic-1 pt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <p><strong>MX8100T : Le plus petit, à travers le mur, recycleur complet</strong><br /> Le MX8100T donne aux institutions financières la possibilité d'offrir des fonctionnalités complètes, y compris les dépôts d'espèces et de chèques, et la sélection de dénominations grâce à la dernière technologie de recyclage d'aujourd'hui. La conception modulaire et la haute disponibilité ont fait du MX8100T un choix populaire pour répondre aux demandes changeantes, en particulier lorsque l'espace est primordial.
                            <br />Le MX8100T est un recycleur de billets à faible encombrement qui peut être déployé n'importe où. Une capacité de dépôt complète permet aux institutions financières d'étendre leur présence aux consommateurs.
                            <br />Le MX8100T est équipé d'un recycleur d'espèces haute capacité et de la capacité de prendre en charge la fonctionnalité de dépôt de chèques.</p>
                            </div>
                    </div> 
                </div> 
            </div> 
    
            <div class="ex-basic-1 pt-3 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h2 class="mb-4">les fonctions</h2>
                            <img class="img-fluid mb-5" src="images/dab01.jpg" alt="DAB"/>
                            <img class="img-fluid mb-5" src="images/dab02.jpg" alt="DAB"/>
                            <a class="btn-solid-reg mb-5" href="/contact">Contactez-nous</a>
                        </div> 
                    </div> 
                </div>
            </div>
            
            {/* End Content */}
            <FooterAndCopyright/>
        </>
    )
}