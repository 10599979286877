import {
  BrowserRouter,
  Router
} from "react-router-dom";

import Business from "./routes/Business";

function App() {
  return (
    <BrowserRouter>
      <Business />
    </BrowserRouter>
  );
}

export default App;
