import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function CaisseEnregistreuse() {
    
    return (
        <>
            <NavBar/>
            {/* Start content */}

            <header class="ex-header bg-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h1>Caisse enregistreuse</h1>
                        </div> 
                    </div> 
                </div> 
            </header> 

            {/* Basic */}
            <div class="ex-basic-1 pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <img class="img-fluid mt-5 mb-3" src="images/caisse_enregistreuse.jpg" alt="Caisse enregistreuse" />
                        </div> 
                    </div> 
                </div> 
            </div> 
    


            {/* Basic */}
            <div class="ex-basic-1 pt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <p><strong>C68</strong><br />
                            · Conception de point de vente tout-en-un avec imprimante, clavier, lecteur de codes-barres et tiroir-caisse ;<br />
                            · Écran HD de 15,6 pouces avec des fonctionnalités résistantes à l'huile, à l'eau et à l'usure ;<br />
                            · La caméra de reconnaissance faciale de profondeur 3D permet le paiement facial et la gestion des membres;<br />
                            · La conception intégrée vous offre un environnement de caisse bien rangé;<br />
                            · Couleur noir profond ou blanc pur pour votre sélection ; </p>
                            <p><strong>EF910</strong><br />EF910 est un ECR intelligent alimenté par le système Android avec processeur quad-core. Son design élégant offre une excellente expérience utilisateur. L'ECR intelligent EF910 peut être largement utilisé dans les secteurs de la vente au détail, de l'alimentation, de l'hôtellerie, des supermarchés et de la santé.<br />Performance excellente,CPU ARM quadricœur 1,8 GHz, Espace mémoire 2 Go de RAM + 16 Go de ROM,4 Go de RAM + 32 Go/64 Go de ROM (en option), Carte Micro SD prise en charge (jusqu'à 128 Go), Écran plus grand et écran tactile, Affichage Principal : écran tactile capacitif 15,6" 1920*1080, Secondaire : 15,6" 1920*1080 (facultatif), 10.1 "1280*800 (facultatif),<br />Plusieurs modes pour plusieurs scénarios : Option 1 : écran simple 15,6" / Option 2 : Écran principal 15,6" (pour caissier) + secondaire
                            écran 15,6" (pour le client) / Option 3 : écran principal 15,6" (pour le client) + secondaire
                            écran 10.1" (pour le client).<br />Excellente efficacité d'impression<br />Avec une vitesse d'impression pouvant atteindre 250 mm/s, le massicot automatique intégré de l'EF910 contribue à améliorer l'efficacité de l'impression et à réduire les opérations manuelles.	</p>				 
                        </div> 
                    </div> 
                </div> 
            </div> 
    
            {/* <!-- Basic --> */}
            <div class="ex-basic-1 pt-3 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h2 class="mb-4">les fonctions</h2>
                            <img class="img-fluid mb-5" src="images/caracteristiques.jpg" alt="caracteristiques"/>
                            <img class="img-fluid mb-5" src="images/caracteristiques_2.jpg" alt="caracteristiques"/>
                            
                            <a class="btn-solid-reg mb-5" href="/contact">Contactez-nous</a>
                        </div> 
                    </div> 
                </div> 
            </div> 

            {/* End Content */}
            <FooterAndCopyright/>
        </>
    )
}







    
  

