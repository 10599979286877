import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import navbarItems from '../data/navbarItems';
import classnames from 'classnames'

export default function NavBar(){
    const [activeItem, setActiveItem] = useState('home');

    useEffect(() => {
        const activeItem = navbarItems.find(item => item.path === window.location.pathname || item.subMenu?.map((smi) => smi.path)?.includes(window.location.pathname));
        setActiveItem(activeItem?.name || 'home');
    }, []);

    return (
        <nav id="navbar" className="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
            <div className="container">
                <Link to="/home"><img src="images/logo_klapeers.png" alt="klapeers" /></Link> 
                <button className="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto navbar-nav-scroll">
                        {
                            navbarItems.map((item, index) => {
                                return (
                                    <li key={index} className={classnames("nav-item", { "dropdown": !!item.subMenu })}>
                                        <Link 
                                            className={classnames(
                                                'nav-link',
                                                {'active': activeItem === item.name},
                                                {'dropdown-toggle': !!item.subMenu }
                                            )}
                                            aria-current="page" 
                                            to={item.path || "#"}
                                            {...(item.subMenu && { 
                                                "data-bs-toggle": "dropdown",
                                                "aria-expanded": false,
                                                id: "dropdown01"
                                            })}
                                        >
                                            {item.label}
                                        </Link>
                                        {item.subMenu && 
                                        <ul className='dropdown-menu' aria-labelledby="dropdown01">
                                            {item.subMenu.map((subItem, subIndex) => {
                                                return (
                                                    <>
                                                    <li key={subIndex}>
                                                        <Link className="dropdown-item" to={subItem.path}>{subItem.label}</Link>
                                                    </li>
                                                    {subIndex < item.subMenu.length - 1 && <div className="dropdown-divider"></div>}
                                                    </>
                                                )
                                            })}
                                        </ul>
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <span className="nav-item">
                        <Link className="btn-outline-sm" to="/contact">Contactez nous</Link>
                    </span>
                </div> 
            </div> 
        </nav> 
    )
}
