import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function Kiosque() {
    
    return (
        
            <>
                <NavBar/>
                {/* Start content */}

                {/* Header */}
                <header class="ex-header bg-gray">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-10 offset-xl-1">
                                <h1>Kiosque</h1>
                            </div>
                        </div>
                    </div>
                </header> 

                {/* Basic */}
                <div class="ex-basic-1 pt-5 pb-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <img class="img-fluid mt-5 mb-3" src="images/kiosque.jpg" alt="Kiosque"/>
                            </div> 
                        </div> 
                    </div> 
                </div> 
                
                {/* Basic */}
                <div class="ex-basic-1 pt-4">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-10 offset-xl-1">
                                <p><strong>K15</strong><br />K15 s'engage à améliorer les services publics intelligents en matière d'auto-file d'attente et de billetterie. La machine de file d'attente K15 a un écran tactile de 17 pouces résistant à l'usure et aux rayures et prend en charge plusieurs fonctions, telles qu'une imprimante thermique, un scanner de codes QR, un lecteur NFC, etc. Les caractéristiques de la borne de file d'attente K15 sont les suivantes : Android 7.1 OS, hexa- Processeur de base à 1,6 GHz, facile à utiliser avec diverses applications logicielles. L'écran capacitif HD de 17 pouces permet au kiosque d'afficher chaque étape et chaque information. La tête d'impression de 80 mm imprime des épreuves de haute qualité à une vitesse d'impression de 150 mm/s. Le lecteur de carte NFC permet de lire le transit sans contact et la carte de membre. Le scanner de code QR prend en charge la plupart des codes 1D/2D. Nous fournissons un service personnalisé, de l'apparence, du logo de la marque, de la fonction supplémentaire à l'emballage.</p>
                                </div> 
                        </div> 
                    </div> 
                </div> 
               
                
                <div class="ex-basic-1 pt-3 pb-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-10 offset-xl-1">
                                <h2 class="mb-4">les fonctions</h2>
                                <img class="img-fluid mb-5" src="images/kiosque01.jpg" alt="kiosque klapeers"/>
                                <img class="img-fluid mb-5" src="images/kiosque02.jpg" alt="kiosque klapeers"/>
                                <img class="img-fluid mb-5" src="images/kiosque03.jpg" alt="kiosque klapeers"/>
                                <a class="btn-solid-reg mb-5" href="/contact">Contactez-nous</a>
                            </div> 
                        </div> 
                    </div> 
                </div> 

                {/* End Content */}
                <FooterAndCopyright/>
        </>
    )
}