import NavBar from "../components/Navbar"
import { Routes, Route ,Router} from 'react-router-dom';
// React Views
import Home from "../views/Home";
import AboutUs from "../views/AboutUs";
import KlapeersCard from "../views/KlapeersCard";
import PointDeVente from "../views/PointDeVenteMobile";
import CaisseEnregistreuse from "../views/CaisseEnregistreuse";
import Kiosque from "../views/Kiosque";
import ValidateurBillet from "../views/ValidateurBillet";
import DistributeurAutoBillet from "../views/DistributeurAutoBillet";
import Solution from "../views/Solution";
import FreeSaas from "../views/FreeSass";
import Contact from "../views/Contact";
import Gamme from "../views/Gamme";

export default function Business() {
  return (
    <>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/clapeers-cards" element={<KlapeersCard />} />
            <Route path="/point-de-vente-mobile" element={<PointDeVente />} />
            <Route path="/caisse-enregistreuse" element={<CaisseEnregistreuse />} />
            <Route path="/kiosque" element={<Kiosque />} />
            <Route path="/validateur-de-billet" element={<ValidateurBillet />} />
            <Route path="/distributeur-auto-billet" element={<DistributeurAutoBillet />} />
            <Route path="/solutions" element={<Solution />} />
            <Route path="/free-saas" element={<FreeSaas />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gamme" element={<Gamme />} />
            <Route path="*" element={<Home />} />
        </Routes>
    </>
  );
}