import React from 'react';
import CountUp from 'react-countup';

const StatCounter = ({ name, value, duration }) => {
  return (
    <div className="d-inline-flex m-5 flex-column justify-content-center">
      <CountUp
        className="purecounter"
        start={0}
        end={value}
        duration={duration}
        formattingFn={(value) => value} // Replace commas with dots
      />
      {name}
    </div>
  );
};

export default StatCounter;
