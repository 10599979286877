import NavBar from "../components/Navbar"

export default function AboutUs() {
    
    return (
        <>
            {/* Navigation */}
            <NavBar/>
            {/* End of navigation */}

            {/* Header */}
            <header className="ex-header bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <h1>A propos de nous</h1>
                        </div> 
                    </div> 
                </div> 
            </header>

            {/* Basic */}
            <div className="ex-basic-1 pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                        <h2 className="mb-3">Bienvenue dans l'écosystème digital africain tout-en-un Klapeers, je suis Hervé le fondateur.</h2>
                            <p>Pendant mes études sur le digital, je constatais qu’aucune plateforme n’avait été conçue en tenant compte des réalités africaines, plus encore, aucune entreprise n’avait été préparée à l’avènement du digital en Afrique.</p>
                            <p className="mb-4">L’uberisation est encore un terme très peu compris par les opérateurs économiques en Afrique, pourtant, la population africaine de plus en plus jeune, s’approprie les nouvelles technologies afin d’interagir entre elle et avec le reste du monde.</p>
                            <p>L’utilisation des ordinateurs, tablettes, phablettes, smartphones et internet de plus en plus répandu a profondément modifié les habitudes de consommation en afrique d’où la nécessité pour les acteurs du continent de tout type de secteur d’activité de se lancer dans leurs transformations numériques.</p>
                            <p>Entre les réseaux sociaux, les messageries instantanées, les outils de gestion de la relation client, les outils de travaux collaboratifs, on se perd très facilement dans l’environnement des GAFA.</p>
                            <p>Se posait donc une question : Comment réussir sa transformation numérique en afrique et l’optimiser afin de booster son chiffre d’affaire, sécuriser ses ventes et fidéliser sa clientèle ?</p>
                            <p>Jusqu’ici sur papier ce qui est devenu un écosystème digital africain, je venais de créer une plateforme communautaire locale, ouverte aux marchés internationaux et décidais dans la foulée que le seul outil de communication serait cette plateforme.</p>
                            <p>La société Klapeers vit le jour avec sa plateforme communautaire www.klapeers.com et rentrait vite en résonnance auprès de ses partenaires stratégiques : HOSTEUR, GLOBALSIGN, INFOBIP, DHL, VISA, MASTERCARD, TELPO et maintenant AZAMRA CAPITAL LLC</p>
                            <p>La mission de KLAPEERS, permettre aux acteurs en afrique de réussir leur transformation numérique en repoussant leurs limites actuelles et en les accompagnant tout au long de leur processus digital d’où la devise "Make it Possible." : Concrétisez la transformation digitale de votre métier.</p>
                            <p>Le travail et l’innovation sont indissociables dans les valeurs de KLAPEERS et de toute son Equipe. C’est pourquoi nous développons sans cesse au sein de notre écosystème digitale tout-en-un, des multitudes de plateforme en mode SaaS, pour permettre à nos clients de bénéficier de toutes nos expertises dans un seul et unique endroit avec un suivi en temps réel par nos Digital Advisor.</p>
                            <p>Vous l’aurez compris, nous sommes aux cotés de nos clients et les accompagnons dans l’accomplissement de leurs rêves numériques</p>
                            <p>Ceci est dans notre ADN et cela nous a valu en décembre 2021 le prix du meilleur projet technologique décerné aux APECCAM AWARDS par l'Association professionnelle des établissements de crédit du Cameroun : APECCAM</p>
                            <p>Au sein de notre Equipe, au sein de nos valeurs, cette mission nous incombe depuis le premier jour de KLAPEERS, parce que nous existons pour vous aider, tout ce qui vous parait impossible, avec klapeers devient possible.</p>
                            <p><b>KLAPEERS,<br />Make it Possible.</b></p>
                        </div> 
                    </div> 
                </div> 
            </div>

            <div id="paiement" className="cards-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="col-lg-6 col-xl-7">Que peut offrir Klapeers ?</h2>
                        </div> 
                    </div> 
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <img className="img-fluid" src="images/k.jpg" alt="Logo Klapeers"/>
                                <div className="card-body">
                                    <h5 className="card-title">Terminal intelligent</h5>
                                    <p className="card-text">La famille de produits couvre le paiement intelligent, la vente au détail intelligente, la sécurité intelligente, le transport intelligent et le domaine V-loT.</p>
                                </div>
                            </div>
                            
                            <div className="card">
                                <img className="img-fluid" src="images/cloud_k.jpg" alt="Paiement facial"/>
                                <div className="card-body">
                                    <h5 className="card-title">Plateforme Cloud</h5>
                                    <p className="card-text">L'ecosystème digitale offre une multitude de plateformes saas auto-développées comprenant Klapeers Entreprise Center, Klapeers Travel Center, Klapeers Shopping Center, Klapeers PharmaCenter, Klapeers Education Center, Klapeers Advertising Center. Etc.</p>
                                </div>
                            </div>
                           
                            <div className="card">
                                <img className="img-fluid" src="images/scenarios.jpg" alt="Developpemen sur mesure"/>
                                <div className="card-body">
                                    <h5 className="card-title">Solutions basées sur des scénarios personnalisés</h5>
                                    <p className="card-text">Nous développons une série de solutions comprenant le paiement intelligent, la vente au détail intelligente, le transport intelligent, la sécurité des gouvernements et des entreprises, les soins de santé intelligents, etc.</p>
                                </div>
                            </div>
                            
                        </div> 
                    </div> 
               
                </div> 
            </div>

        {/* Services */}
            <div id="services" className="cards-1 bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="text-container">
                                <h2>Nos partenaires stratégiques dans le monde</h2>
                                <p>Ils sont pour la plupart leader dans leurs secteurs d'activités respectifs ce qui nous permet d'atteindre de très haut niveau d'exigence qualitatif dans la mise en production de nos solutions auprès de nos clients</p>
                            
                                <ul className="list-unstyled li-space-lg">
                                    <li className="d-flex">
                                        <i className="fas fa-square"></i>
                                        <div className="flex-grow-1">une présence et notoriété mondiale</div>
                                    </li>
                                    <li className="d-flex">
                                        <i className="fas fa-square"></i>
                                        <div className="flex-grow-1">Un haut niveau de qualité et de délivrabilité</div>
                                    </li>
                                    <li className="d-flex">
                                        <i className="fas fa-square"></i>
                                        <div className="flex-grow-1">Un très haut niveau de sécurité et de respecte du RGPD</div>
                                    </li>
                                </ul>
                            </div>
                        </div> 
                        <div className="col-lg-7">
                            <div className="card-container">

                                <div className="card">
                                    <img src="images/visa_1.png" alt="Visa"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/mastercard_1.png" alt="Mastercard"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/temenos_1.png" alt="Temenos"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/dhl_1.png" alt="DHL"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/uba_1.png" alt="UBA"/>
                                </div>
                               
                                <div className="card">
                                    <img src="images/fss_1.png" alt="fss payement gateway"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/nexgo_1.png" alt="Nexgo"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/telpo_1.png" alt="telpo"/>
                                </div>
                               
                                <div className="card">
                                    <img src="images/hosteur_1.png" alt="Hosteur"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/infobip_1.png" alt="Infobip"/>
                                </div>
                              
                                <div className="card">
                                    <img src="images/globaltt_1.png" alt="DHL"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/huawei_1.png" alt="Huawei"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/ncr_1.png" alt="NCR"/>
                                </div>
                               
                                <div className="card">
                                    <img src="images/grg_banking_1.png" alt="GRG Banking"/>
                                </div>
                               
                                <div className="card">
                                    <img src="images/hyosung_1.png" alt="Hyosung TNS"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/cypher_1.png" alt="NCR"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/afriland_1.png" alt="GRG Banking"/>
                                </div>
                                
                                <div className="card">
                                    <img src="images/ville_kribi_1.png" alt="Hyosung TNS"/>
                                </div>
                                
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div>
            
            <br /><br />
            {/* Cards */}
            <div className="ex-cards-1 pt-3 pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            
                            <div className="card">
                                <ul className="list-unstyled">
                                    <li className="d-flex">
                                        <span className="fa-stack">
                                            <span className="fas fa-circle fa-stack-2x"></span>
                                            <span className="fa-stack-1x">1</span>
                                        </span>
                                        <div className="flex-grow-1">
                                            <h5>Siège</h5>
                                            <p>KLAPEERS SARL<br />
                                            CAMEROUN<br />
                                            755 Douala<br />
                                            Tél. : +237 233 476 150<br />
                                            Email : afrique(at)klapeers.com<br />
                                            No Registre de commerce RC/DLN/2020/B/468<br />
                                            No contribuable : M022014406966K</p>
                                        </div>
                                        </li>
                                    </ul>
                                </div> 
                                
                                    <div className="card">
                                        <ul className="list-unstyled">
                                            <li className="d-flex">
                                                <span className="fa-stack">
                                                    <span className="fas fa-circle fa-stack-2x"></span>
                                                    <span className="fa-stack-1x">2</span>
                                                </span>
                                                <div className="flex-grow-1">
                                                    <h5>Afrique centrale</h5>
                                                    <p>KLAPEERS<br />
                                                    GABON<br />
                                                    Libreville<br />
                                                    Tél. : +241 77 670 135<br />
                                                    Email : libreville(at)klapeers.com</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div> 
                            
                            <div className="card">
                                <ul className="list-unstyled">
                                    <li className="d-flex">
                                        <span className="fa-stack">
                                            <span className="fas fa-circle fa-stack-2x"></span>
                                            <span className="fa-stack-1x">3</span>
                                        </span>
                                        <div className="flex-grow-1">
                                            <h5>Afrique de l'ouest</h5>
                                            <p>KLAPEERS<br />
                                            COTE D'IVOIRE<br />
                                            Abidjan<br />
                                            Tél. : +225 0749 863 095<br />
                                            Email : abidjan(at)klapeers.com</p>
                                        </div>
                                    </li>
                                </ul>
                            </div> 
                        
                        </div>    
                    </div>    
                </div>    
            </div>


            {/* Basic */}
            <div className="ex-basic-1 pt-3 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            
                            <a className="btn-solid-reg mb-5" href="/contact">Nous écrire</a>
                        </div> 
                    </div> 
                </div> 
            </div>
            
            {/* Footer */}
            <div  className="footer bg-gray">
                    <div  className="container">
                        <div  className="row">
                            <div  className="col-lg-12">
                                <div  className="footer-col first">
                                    <h6>A propos de Klapeers</h6>
                                    <p  className="p-small">Depuis 2022, Klapeers est le partenaire technique des villes africaines et développe le concept de Smart City en afrique subsaharienne.</p>
                                </div>  
                                <div  className="footer-col second">
                                    <h6>Accès rapide</h6>
                                    <ul  className="list-unstyled li-space-lg p-small">
                                        <li><a href="#header">Accueil</a>, <a href="#details">A propos de nous</a>, <a href="#services">Produits</a>, <a href="#services">Solutions</a>, <a href="#services">Klapeers Cloud</a>, <a href="#services">Développeurs</a>, <a href="#contact">Contact</a></li>
                                        <li><a href="#">Termes et conditions</a>, <a href="#">Politique de confidentialité</a></li>
                                        
                                        
                                    </ul>
                                </div>  
                                <div  className="footer-col third">
                                
                                    <span  className="fa-stack">
                                        <a href="https://www.klapeers.com">
                                            <img src="images/klapeers_social.png" alt="klapeers"/>
                                        </a>
                                    </span>
                                    <p  className="p-small">Make it Possible. <a href="mailto:contact@site.com"><strong>afrique@klapeers.com</strong></a></p>
                                </div>  
                            </div> 
                        </div>  
                    </div>  
            </div>
            {/* End of footer */}

            {/* Copyright */}
            <div  className="copyright bg-gray">
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-12">
                            <p  className="p-small">© Tous droits réservés 2024, <a href="index.html">Klapeers</a></p>
                        </div> 
                    </div>
                </div>  
            </div>
    
        </>
    )
}