import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function ValidateurBillet() {
    
    return (
        <>
            <NavBar/>
            {/* Start content */}
            
            {/* Basic */}
            <header class="ex-header bg-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h1>Validateur de billet</h1>
                        </div>
                    </div> 
                </div> 
            </header>

            {/* Basic */}
            <div class="ex-basic-1 pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <img class="img-fluid mt-5 mb-3" src="images/validateur_billet.jpg" alt="Validateur de billet"/>
                        </div> 
                    </div> 
                </div> 
            </div> 
        
            {/* Basic */}
            <div class="ex-basic-1 pt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <p><strong>TS530</strong><br />La machine de collecte des tarifs des transports en commun (FCM) TPS530 peut être installée dans les bus pour des fonctions polyvalentes telles que le calcul des frais de déplacement et la déduction du prix du bus, et afficher la charge de la carte et la charge restante.
                            <br />Écran 5 pouces (écran tactile/original), port riche (DC\RS485\RS232、USB、Micro USB) . Le décodage dur du code QR ou la caméra OCR assure une vitesse de reconnaissance ultra-rapide . Paiement NFC, le NFC financier est pris en charge avec un certificat . 2SIM 2PSAM, avec un maximum de 8PSAM, carte TF de 64 Go, résolvez la question des différentes cartes . La fonction de positionnement GPS aide au suivi en temps réel des véhicules.</p>
                            </div> 
                    </div> 
                </div> 
            </div> 
    
            {/* Basic */}
            <div class="ex-basic-1 pt-3 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h2 class="mb-4">les fonctions</h2>
                            <img class="img-fluid mb-5" src="images/validateur01.jpg" alt="validateur de billet"/>
                            <img class="img-fluid mb-5" src="images/validateur02.jpg" alt="validateur de billet"/>
                            <img class="img-fluid mb-5" src="images/validateur03.jpg" alt="validateur de billet"/>
                            <a class="btn-solid-reg mb-5" href="/contact">Contactez-nous</a>
                        </div>
                    </div> 
                </div> 
            </div> 
            
            {/* End Content */}
            <FooterAndCopyright/>
        </>
    )
}