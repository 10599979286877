// replace label by label everywhere in the navbarItems array
const navbarItems = [
  {
    label: 'Accueil',
    path: '/home',
    name: "home",
  },
  {
    label: 'A propos de nous',
    path: '/about',
    name: "about",
  },
  {
    label: 'Nos produits',
    name: "products",
    subMenu: [
      {
        label: 'Carte de fidélité VISA Prepayée',
        path: '/clapeers-cards'
      },
      {
        label: 'Point de vente mobile',
        path: '/point-de-vente-mobile'
      },
      {
        label: 'Caisse enregistreuse',
        path: '/caisse-enregistreuse'
      },
      {
        label: 'Kiosque',
        path: '/kiosque'
      },
      {
        label: 'Validateur de billet',
        path: '/validateur-de-billet'
      },
      {
        label: 'Distributeur automatique de billet',
        path: '/distributeur-auto-billet'
      }
    ]
  },
  {
    label: 'Nos solutions',
    name: "solutions",
    subMenu: [
      {
        label: 'Paiement',
        path: '/solutions'
      },
      {
        label: 'Vendre en détail',
        path: '/solutions#vente_detail'
      },
      {
        label: 'eID',
        path: '/solutions#eid'
      },
      {
        label: 'Sécurité',
        path: '/solutions#securite'
      },
      {
        label: 'Transport',
        path: '/solutions#transport'
      },
      {
        label: 'Santé',
        path: '/solutions#sante'
      },
      {
        label: 'Education',
        path: '/solutions#education'
      }
    ]
  },
  {
    label: 'Klapeers Cloud',
    name: "cloud",
    subMenu: [
      {
        label: 'Klapeers SaaS',
        path: '/free-saas'
      },
      {
        label: 'Hosteur Africa',
        path: 'https://www.hosteur.africa/'
      }
    ]
  },
  {
    label: 'Développeurs',
    name: "developers",
    subMenu: [
      {
        label: 'Klapeers Dev',
        path: '/about'
      },
      {
        label: 'Klapeers APIs',
        path: '/about'
      },
      {
        label: 'Premier pas',
        path: '/about'
      },
      {
        label: 'FAQ',
        path: '/about'
      }
    ]
  }
]

export default navbarItems