export default function FooterAndCopyright(){

    return (
        <>
            {/* Footer */}
            <div  className="footer bg-gray">
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-12">
                            <div  className="footer-col first">
                                <h6>A propos de Klapeers</h6>
                                <p  className="p-small">Depuis 2022, Klapeers est le partenaire technique des villes africaines et développe le concept de Smart City en afrique subsaharienne.</p>
                            </div>  
                            <div  className="footer-col second">
                                <h6>Accès rapide</h6>
                                <ul  className="list-unstyled li-space-lg p-small">
                                    <li><a href="#header">Accueil</a>, <a href="#details">A propos de nous</a>, <a href="#services">Produits</a>, <a href="#services">Solutions</a>, <a href="#services">Klapeers Cloud</a>, <a href="#services">Développeurs</a>, <a href="#contact">Contact</a></li>
                                    <li><a href="#">Termes et conditions</a>, <a href="#">Politique de confidentialité</a></li>
                                    
                                </ul>
                            </div>  
                            <div  className="footer-col third">
                            
                                <span  className="fa-stack">
                                    <a href="https://www.klapeers.com">
                                        <img src="images/klapeers_social.png" alt="klapeers"/>
                                    </a>
                                </span>
                                <p  className="p-small">Make it Possible. <a href="mailto:contact@site.com"><strong>afrique@klapeers.com</strong></a></p>
                            </div>  
                        </div> 
                    </div>  
                </div>  
            </div>
            {/* End of footer */}

            {/* Copyright */}
            <div  className="copyright bg-gray">
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-12">
                            <p  className="p-small">© Tous droits réservés 2024, <a href="index.html">Klapeers</a></p>
                        </div> 
                    </div>
                </div>  
            </div>
        </>
    )
}