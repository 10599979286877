import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function Solution() {
    
    return (
        <>
            <NavBar/>
            {/* Start content */}

            <div id="paiement" class="cards-2">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="col-lg-6 col-xl-7">Paiement</h2>
                            <p>Solution de paiement complète, couvertures avec paiement avec et sans contact, paiement par code QR, paiement facial, etc.</p>
                        </div> 
                    </div> 
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <img class="img-fluid" src="images/project-1.jpg" alt="Paiement par carte"/>
                                <div class="card-body">
                                    <h5 class="card-title">Paiement par carte</h5>
                                    <p class="card-text">Le paiement par carte reste le principal moyen de paiement, même si les portefeuilles numériques deviennent de plus en plus populaires. Le paiement par carte comprend les cartes de crédit couramment utilisées, les paiements par carte de débit, ainsi que les cartes avec puces biométriques, les cartes prépayées et autres cartes de paiement. Les méthodes de paiement diversifiées obligent les commerçants à ajouter plus d'équipements et d'investissements. Parce que les commerçants doivent accepter plusieurs méthodes de paiement, sinon la transaction peut ne pas être réalisée. Le point de vente traditionnel offre peu de fonctionnalités.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                        
                            <div class="card">
                                <img class="img-fluid" src="images/project-2.jpg" alt="Paiement facial"/>
                                <div class="card-body">
                                    <h5 class="card-title">Paiement facial</h5>
                                    <p class="card-text">Le paiement par reconnaissance faciale biométrique se développe progressivement malgré les préoccupations concernant la confidentialité et la sécurité des personnes. En Chine, le paiement par reconnaissance faciale a été introduit dans les dépanneurs, les supermarchés et d'autres scénarios de vente au détail, les scénarios de transport public tels que les métros et les bus, les commandes et les déductions en libre-service sur les campus, etc. Le paiement facial est en cours de développement en Russie, en Corée du Sud, etc. . , Et les normes de sécurité des paiements connexes ont également commencé à être rédigées.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                            <div class="card">
                                <img class="img-fluid" src="images/project-3.jpg" alt="QR-Payer"/>
                                <div class="card-body">
                                    <h5 class="card-title">QR-Payer</h5>
                                    <p class="card-text">Le paiement par code QR, qui est l'un des représentants du paiement mobile, est également appelé paiement sans carte, car l'opération de paiement consistant à scanner le code QR est simple et pratique, ce qui améliore l'efficacité du paiement et ouvre davantage le paiement électronique, ce qui considérablement répond aux besoins des petites et moyennes entreprises. La demande de petites collections réduit la circulation des billets. Des pays tels que le Royaume-Uni, l'Indonésie, le Brésil et d'autres pays accueillent et encouragent les méthodes de paiement par code QR.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                        </div> 
                    </div> 
			
                    <p id="vente_detail"><br /><br /></p>
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="col-lg-6 col-xl-7">Vendre en détail</h2>
                            <p>Des solutions de vente au détail intelligentes, brisent les silos de données et aident à la gestion centralisée des données multicanaux.</p>
                        </div> 
                    </div> 
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="card">
                                <img class="img-fluid" src="images/project-4.jpg" alt="Caisse enregistreuse de détail"/>
                                <div class="card-body">
                                    <h5 class="card-title">Caisse enregistreuse de détail</h5>
                                    <p class="card-text">Avec la reprise de l'industrie mondiale de la vente au détail et les changements de comportement des consommateurs, des petits magasins de proximité aux grands supermarchés, de nouveaux développements sont explorés afin de parvenir à une croissance commerciale. Les mises à niveau intelligentes de la gestion numérique et les paiements sécurisés sont inévitables pour tous les détaillants. Sur la base de ces besoins communs, Klapeers peut fournir des solutions de vente au détail intégrées en ligne et hors ligne, intégrer diverses fonctions telles que la caisse, le paiement mobile, la commande, la vente à emporter, les activités de marketing, la gestion des membres VIP et fournir plusieurs terminaux et plates-formes SaaS.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                        
                            <div class="card">
                                <img class="img-fluid" src="images/project-5.jpg" alt="Le restaurant"/>
                                <div class="card-body">
                                    <h5 class="card-title">Le restaurant</h5>
                                    <p class="card-text">Le restaurant intelligent de Klapeers englobe l'ensemble des solutions, y compris les menus électroniques, le système de caisse enregistreuse, les commandes intelligentes, l'affichage de la cuisine et la gestion des effectifs, la gestion des comptes, etc. Le système peut gérer plusieurs restaurants simultanément pour répondre aux besoins des petites et moyennes entreprises.<br />Le système de point de vente intelligent en tant que gestion essentielle de restaurant est particulièrement important. Grâce au registre de point de vente intelligent, les commandes en ligne peuvent être soumises hors ligne en temps réel, et la consommation hors ligne est liée à la promotion en ligne, aux points d'adhésion, au paiement par porte-monnaie électronique et au paiement par code QR, réalisant ainsi le nouvel écosystème de restaurants.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                            
                            <div class="card">
                                <img class="img-fluid" src="images/project-6.jpg" alt="En libre service"/>
                                <div class="card-body">
                                    <h5 class="card-title">En libre service</h5>
                                    <p class="card-text">Le libre-service signifie la pratique de se servir soi-même. Habituellement, les gens pouvaient faire le libre-service en utilisant une machine en libre-service. Les guichets automatiques les plus connus sont peut-être les distributeurs automatiques de billets (Automatic Teller Machines). Cependant, le rôle des bornes libre-service est devenu plus important que jamais, et il le sera de plus en plus à l'avenir.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                            
                        </div> 
                    </div> 
                    
                    <p id="eid"><br /><br /></p>
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="col-lg-6 col-xl-7">eID</h2>
                            <p>Combiné avec la technologie biométrique, pour inscrire et authentifier avec précision les eID valides, y compris la carte d'identité nationale, le gouvernement électronique, l'identification de l'électeur, etc.</p>
                        </div> 
                    </div> 
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <img class="img-fluid" src="images/project-7.jpg" alt="Biométrie"/>
                                <div class="card-body">
                                    <h5 class="card-title">Biométrie</h5>
                                    <p class="card-text">La sécurité biométrique est sur une courbe de croissance rapide, selon un certain nombre de rapports de recherche récents. La biométrie fait référence à un système qui mesure les caractéristiques physiologiques uniques d'un individu donné dans le but d'une identification fiable. Certaines des caractéristiques ou facteurs biométriques les plus largement utilisés sont les empreintes digitales, les iris, les modèles de voix et la géométrie spatiale du visage. Les mesures biométriques offrent une alternative pratique et puissante à l'authentification traditionnelle. Avec les mesures biométriques, les utilisateurs n'ont pas à se souvenir de chaînes de valeurs abstraites.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                            
                            <div class="card">
                                <img class="img-fluid" src="images/project-8.jpg" alt="Enregistrement de carte sim"/>
                                <div class="card-body">
                                    <h5 class="card-title">Enregistrement de la carte SIM</h5>
                                    <p class="card-text">Les téléphones portables restent le moyen le plus simple et le plus meurtrier pour les terroristes de faire exploser à distance des explosifs. Afin de freiner cette utilisation mortelle des téléphones portables, les gouvernements de certains pays d'Afrique, du Moyen-Orient et d'Asie du Sud ont déjà imposé l'enregistrement obligatoire des utilisateurs de cartes SIM prépayées. Pour utiliser l'enregistrement SIM obligatoire, les utilisateurs doivent présenter une pièce d'identité valide, une photo ou enregistrer leurs données biométriques avant l'activation de la carte SIM. Les utilisateurs existants qui ne peuvent pas enregistrer leur carte SIM dans le délai spécifié désactiveront leur carte et se déconnecteront du réseau.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                            
                            <div class="card">
                                <img class="img-fluid" src="images/project-9.jpg" alt="KYC Bancaire"/>
                                <div class="card-body">
                                    <h5 class="card-title">KYC Bancaire</h5>
                                    <p class="card-text">La sécurité, dans le domaine bancaire et financier, est l'une des priorités les plus diverses des organisations et de leurs clients. Dans le monde d'aujourd'hui de numérisation rapide et d'hyper-connectivité, s'assurer de la sécurité des actifs d'un individu, économiques ou autres, est devenu une entreprise assez onéreuse en raison des menaces croissantes de cybercriminalité. Pour lutter contre de telles situations injustifiées, où les finances et la conformité réglementaire d'un client sont menacées, les gouvernements et les banques du monde entier ont adopté Know You Customer (KYC) pour prévenir l'apparition d'activités frauduleuses et de blanchiment d'argent.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                        </div> 
                    </div> 
                
                    <p id="securite"><br /><br /></p>
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="col-lg-6 col-xl-7">Sécurité</h2>
                            <p>Smart Security fournit des solutions de sécurité électronique personnalisées pour les entreprises et les familles.</p>
                        </div> 
                    </div> 
                    <div class="row">
                        <div class="col-lg-12">

                            
                            <div class="card">
                                <img class="img-fluid" src="images/project-10.jpg" alt="pass santé numérique"/>
                                <div class="card-body">
                                    <h5 class="card-title">Pass santé numérique</h5>
                                    <p class="card-text">Une série de terminaux intelligents prend en charge la lecture de codes QR mobiles, et une variété de formes de produits peuvent fournir toutes sortes d'applications de vérification numérique pour une lecture efficace des passeports santé/passe vert/passeports de vaccins numériques dans plusieurs scénarios.
                                    <br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                        
                            <div class="card">
                                <img class="img-fluid" src="images/project-11.jpg" alt="Controle d acces"/>
                                <div class="card-body">
                                    <h5 class="card-title">Contrôle d'accès</h5>
                                    <p class="card-text">Dans un monde de plus en plus numérique, la protection des informations confidentielles et la gestion des hommes deviennent de plus en plus difficiles. La technologie d'authentification biométrique utilise des traits ou des caractéristiques comportementales uniques, ce qui est plus scientifique et plus efficace pour faire face à ces dilemmes au lieu des méthodes traditionnelles. Pour mettre l'accent sur la sécurité et la protection, un contrôle d'accès a été mis en place partout, comme les écoles, les usines, les bâtiments d'entreprise, etc.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                        </div> 
                    </div> 
			
                    <p id="transport"><br /><br /></p>
                        <div class="row">
                            <div class="col-lg-12">
                                <h2 class="col-lg-6 col-xl-7">Transport</h2>
                                <p>La billettique intelligente transforme le système de transport public, couvrant les métros, les bus, les taxis, etc.</p>
                            </div> 
                        </div> 
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <img class="img-fluid" src="images/project-12.jpg" alt="Bus public"/>
                                    <div class="card-body">
                                        <h5 class="card-title">Bus public</h5>
                                        <p class="card-text">Le marché des transports publics est en pleine croissance partout dans le monde. Les mégadonnées deviennent de plus en plus populaires, des appareils intelligents apparaissent dans tous les coins, il devient urgent de rendre les transports publics plus efficaces et plus pratiques. La combinaison d'un matériel intelligent et d'un système de gestion aidera les transports publics à réduire les coûts, le temps et les risques associés aux problèmes liés au transport.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                    </div>
                                </div>
                                
                                <div class="card">
                                    <img class="img-fluid" src="images/project-13.jpg" alt="Billetterie Mobile"/>
                                    <div class="card-body">
                                        <h5 class="card-title">Billetterie Mobile</h5>
                                        <p class="card-text">Avec la popularisation des téléphones et des appareils mobiles, les déplacements mobiles intelligents s'accélèrent, ce qui favorise également les déplacements verts et les billets sans papier. Cette solution résout la gestion entre la vente de billets intelligents et la vérification des billets et les systèmes de billetterie mobiles sous de nouvelles demandes.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                    </div>
                                </div>
                                
                                <div class="card">
                                    <img class="img-fluid" src="images/project-13-3.jpg" alt="Billetterie Mobile"/>
                                    <div class="card-body">
                                        <h5 class="card-title">Réservation de vol</h5>
                                        <p class="card-text">Une solution innovante qui rend votre activité de réservation de vols en ligne rentable et dynamique. Klapeers Travel Center sera le package parfait pour tous les entrepreneurs qui souhaitent démarrer leur entreprise de réservation de billets de rêve.Connectez votre entreprise de réservation de billets en ligne à un entrepôt de données en intégrant les API GDS et les API de paiement en ligne<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                    </div>
                                </div>
                            </div> 
                        </div> 
			
                        <p id="sante"><br /><br /></p>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h2 class="col-lg-6 col-xl-7">Santé</h2>
                                    <p>Proposez des solutions hygiéniques, sans contact et professionnelles.</p>
                                </div> 
                            </div> 
                            <div class="row">
                                <div class="col-lg-12">

                                <div class="card">
                                    <img class="img-fluid" src="images/project-14.jpg" alt="Détection de temperatuere"/>
                                    <div class="card-body">
                                        <h5 class="card-title">Détection de temperature</h5>
                                        <p class="card-text">Bureaux, ecoles... <br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                    </div>
                                </div>
                            
                                <div class="card">
                                    <img class="img-fluid" src="images/project-15.jpg" alt="klapeers PharmaCenter"/>
                                    <div class="card-body">
                                        <h5 class="card-title">Gestion des pharmacies</h5>
                                        <p class="card-text">Klapeers PharmaCenter garantit un fonctionnement bien organisé, un système de facturation moderne, une gestion des revenus, un mécanisme de suivi des stocks et la dynamisation de votre entreprise.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                    </div>
                                </div>
                                
                                <div class="card">
                                    <img class="img-fluid" src="images/project-18.jpg" alt="klapeers hospiCenter"/>
                                    <div class="card-body">
                                        <h5 class="card-title">Gestion hospitalière</h5>
                                        <p class="card-text">Klapeers hospiCenter intègre les systèmes de gestion OPD et IPD, la pharmacie, le laboratoire, le système de gestion des lits, l'application mobile, le système de rendez-vous, la messagerie electronique sécurisée, la demande éléctronique médicale, la comptabilité, les RH ou lapaie. En outre ce système dispose de 3 parties à savoir portail médécin, portail patient et portail famille.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                    </div>
                                </div>
                            </div> 
                        </div> 
			
                        <p id="education"><br /><br /></p>
                        <div class="row">
                            <div class="col-lg-12">
                                <h2 class="col-lg-6 col-xl-7">Education</h2>
                                <p>Offrez une gestion des établissements scolaires innovante, avec des outils de pilotage à distance et smart</p>
                            </div> 
                        </div> 
                        <div class="row">
                            <div class="col-lg-12">

                            <div class="card">
                                <img class="img-fluid" src="images/project-16.jpg" alt="alternative"/>
                                <div class="card-body">
                                    <h5 class="card-title">Gestion des écoles</h5>
                                    <p class="card-text">Klapeers Education Center est une plateforme complète pour la gestion d’école, qui aide les directeurs, les enseignants, les étudiants et leurs parents à organiser tout le processus pédagogique et facilite la collaboration et la communication entre les parents et l’école. Il a été conçu et testé dans des écoles camerounaises et est personnalisable selon le fonctionnement dans chaque pays avec leurs différentes contraintes. Il vous permet de réaliser les tâches complexes de façon simple et en toute sécurité dans le respect du reglement général sur la protection des données personnelles, grâce à son interface lisible et intuitive, utilisable en deux langues Français/Anglais.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                            
                            <div class="card">
                                <img class="img-fluid" src="images/project-17.jpg" alt="alternative"/>
                                <div class="card-body">
                                    <h5 class="card-title">E-learning</h5>
                                    <p class="card-text">Klapeers Educaton Center est une platefome LMS (learning management system) qui est un type de gestion de l'apprentissage en ligne qui peut aider à creer une plateforme éducative en ligne pour les etablissements scolaires en Afrique. De plus, en utilisant cette plateforme, vous pouvez fournir un environnement educatif polyvalent.<br /><a class="blue no-line" href="#contact">En savoir plus...</a></p>
                                </div>
                            </div>
                        </div> 
                    </div> 
                </div> 
            </div>

            {/* Introduction */}
            <div id="introduction" class="basic-1 bg-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-xl-7">
                            <h2>Gestion centralisée des équipements décentralisés, télésurveillance des équipements.</h2>
                            <p>Déploie instantanément un système d'entreprise "Cloud + Terminal" à l'aide de "Klapeers Center"</p>
                        </div> 
                        <div class="col-lg-6 col-xl-5">
                            <img class="img-fluid" src="images/cloud.png" alt="alternative"/>
                        </div> 
                        
                    </div>
                </div> 
            </div> 

            <div id="contact" class="form-1">
                <img class="decoration-star" src="images/decoration-star.svg" alt="alternative"/>
                <img class="decoration-star-2" src="images/decoration-star.svg" alt="alternative"/>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="image-container">
                                <img class="img-fluid" src="images/contact.png" alt="alternative"/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="text-container">
                                <h2>Contactez notre équipe d'experts et discutons ensemble de votre projet</h2>

                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control-input" placeholder="Nom" required/>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control-input" placeholder="Email" required/>
                                    </div>
                                    <div class="form-group">
                                        <input type="numbers" class="form-control-input" placeholder="Telephone" required/>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control-input" placeholder="Objet" required/>
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control-textarea" placeholder="Message" required></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="form-control-submit-button">Envoyer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
            {/* End Content */}
            <FooterAndCopyright/>
        </>
    )
}