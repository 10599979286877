import NavBar from "../components/Navbar";
import FooterAndCopyright from "../components/Footer";

export default function PointDeVente() {
    
    return (
        <>
            <NavBar/>
            {/* Start content */}
            {/* <!-- Header --> */}
            <header class="ex-header bg-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h1>Point de vente mobile</h1>
                        </div> 
                    </div> 
                </div> 
            </header>
   
            {/* <!-- Basic --> */}
            <div class="ex-basic-1 pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <img class="img-fluid mt-5 mb-3" src="images/pdv_mobile.jpg" alt="PDV mobile" />
                        </div> 
                    </div> 
                </div> 
            </div> 

            <div class="ex-basic-1 pt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <p><strong>Le TPS900</strong><br />
                            Basé sur la plate-forme d'exploitation Android, le TPS900 est un puissant appareil EFT-POS intelligent avec acceptation multi-paiement et identification biométrique activée. Écran de 5,5 pouces + version Android 10 + puce Qualcomm. Multi-paiement: MSR, Carte de crédit Carte à puce, sans contact / NFC, le paiement d'empreintes digitales, le paiement QR Code, et même le paiement du visage, etc . Normes de certification de haute sécurité : PCI 6.X, EMV, PCI, Paywave, Paypass, MIR, Rupay, TQM, CE, FCC, RoHS, BIS, Anatel, etc. Garanti par la technologie de reconnaissance d'empreintes digitales, d'iris et de visage . Forte expansibilité : ajout externe d'OTG, UHF, TPUI, contrôle fiscal, grande batterie et clavier physique.</p>
                            <p><strong>N3</strong><br />
                            Le système d'exploitation de N3 est personnalisé et alimenté par Android avec un système de paiement sécurisé, fournit un environnement de paiement stable et sûr pour les commerçants.<br />L'écran tactile FWVGA de 5,0 " de N3 peut répondre aux diverses exigences des terminaux de point de vente intelligents, afficher clairement les informations de fonctionnement, obtenir rapidement les signatures électroniques des clients et, surtout, vous aider à contrôler facilement le terminal de point de vente en touchant l'écran.<br />Fonctionne plus longtemps pour votre entreprise occupée, l'énorme batterie de 5 200 mAh peut durer une journée complète et prendre en charge plus de 500 transactions sur une seule charge.</p>
                            
                            <p class="mb-5">
                                <strong>N6</strong><br />N6 est l'un des produits révolutionnaires et mini terminal de point de vente intelligent. La conception structurelle est optimisée pour un corps mince de 16,9 mm avec la partie la plus mince de seulement 12,6 mm. Mais N6 est puissant, fonctionne sur un processeur quad-core et accepte tous les modes de paiement des cartes à bande magnétique, cartes à puce, sans contact/NFC, codes-barres 1D et 2D.
                                <br />Vous pouvez obtenir tous les paiements par cartes à bande magnétique, cartes à puce, sans contact/NFC, codes à barres 1D et 2D par ce terminal de point de vente intelligent et mince.<br />Ce terminal de point de vente intelligent est équipé d'un processeur quadricœur avec quatre unités indépendantes et d'une puce de sécurité, le processeur quadricœur offre de meilleures performances car davantage de tâches seront accomplies simultanément, en même temps, la puce de sécurité améliore le niveau de sécurité de ces terminaux de point de vente.
                            </p>
                            <p><strong>N86</strong><br />La qualité et l'apparence impeccables rendent le N86 irrésistible. Il offre des performances élevées grâce au système d'exploitation alimenté par Android 9.0 et un processeur haute vitesse quadricœur de 1,4 GHz. La conception innovante combine les arts et la technologie de protection avancée. Et N86 est économe en énergie, le temps de veille est jusqu'à 240 heures.<br />Performance exceptionnelle, Le N86 est alimenté par Android 9.0 et utilise un processeur haute vitesse quadricœur de 1,4 GHz. Quad-Core 1,4 GHz Mémoire 1 Go de RAM + 8 Go de Flash, 2 Go de RAM + 16 Go de Flash (en option)</p>
                        </div> 
                    </div> 
                </div> 
            </div> 
    
            <div class="ex-basic-1 pt-3 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h2 class="mb-4">Norme de certification de très haute sécurité</h2>
                            <img class="img-fluid mb-5" src="images/pdv_mobile_2.jpg" alt="certification PDV"/>
                            
                            <a class="btn-solid-reg mb-5" href="/contact">Contactez-nous</a>
                        </div> 
                    </div> 
                </div> 
            </div> 
            {/* End Content */}
            <FooterAndCopyright/>
        </>
    )
}